:root {
	--blue: #948fd6;
	--white: #fff;
	--light-blue: #3ed5e0;
	--pink: #f806e9;
	--purple:#c31ff4;
	--orange: #f89406;
	--green: #68dd70;
}

.button {
	border: 1px solid;
	background-color: var(--white);
	transition: background-color 0.5s ease;
	width: 100px;
	text-decoration: none;
	text-align: center;
	margin: 1rem;
	font-family: 'VT323', monospace;
	font-size: 20px;
}

.button:hover {
	color: var(--white);
}


.link {
	margin: 1rem;
	font-family: 'VT323', monospace;
	font-size: 20px;
}

.link:hover {
	cursor: pointer;
	text-decoration: underline;
}

.link-about-short {
	color: var(--blue);
}

.link-about-short:hover {
	color: var(--orange);
}

.link-about-long{
	color: var(--pink);
}

.link-about-long:hover {
	color: var(--orange);
}

.link-portfolio {
	color: var(--light-blue);
}

.link-portfolio:hover {
	color: var(--orange);
}

.btn-github {
	border-color: var(--light-blue);
	color: var(--light-blue);
}

.btn-github:hover {
	background-color: var(--light-blue);
}

.btn-cv {
	border-color: var(--pink);
	color: var(--pink);
}

.btn-cv:hover {
	background-color: var(--pink);
}

.btn-codepen {
	border-color: var(--purple);
	color: var(--purple);
}

.btn-codepen:hover {
	background-color: var(--purple);
}

.btn-linkedin {
	border-color: var(--green);
	color: var(--green);
}

.btn-linkedin:hover {
	background-color: var(--green);
}

.btn-mail {
	border-color: var(--orange);
	color: var(--orange);
}

.btn-mail:hover {
	background-color: var(--orange);
}

.text {
	padding: 1rem;
	font-size: x-large;
	line-height: 0.4in;
}
