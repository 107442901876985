@import url('https://fonts.googleapis.com/css2?family=Athiti:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

* {
	margin: 0;
	padding: 0;
}
.row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

body {
	font-family: 'Athiti', sans-serif;
	margin: 0 10% 0 10%;
}

.column {
	flex: 25%;
}

@media screen and (max-width: 600px) {
	body {
		font-family: 'Athiti', sans-serif;
		margin: 0 10% 0 10%;
	}
	.row {
		flex-direction: column;
	}
	
}
