@import url(https://fonts.googleapis.com/css2?family=Athiti:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=VT323&display=swap);
:root {
	--blue: #948fd6;
	--white: #fff;
	--light-blue: #3ed5e0;
	--pink: #f806e9;
	--purple:#c31ff4;
	--orange: #f89406;
	--green: #68dd70;
}

.button {
	border: 1px solid;
	background-color: #fff;
	background-color: var(--white);
	transition: background-color 0.5s ease;
	width: 100px;
	text-decoration: none;
	text-align: center;
	margin: 1rem;
	font-family: 'VT323', monospace;
	font-size: 20px;
}

.button:hover {
	color: #fff;
	color: var(--white);
}


.link {
	margin: 1rem;
	font-family: 'VT323', monospace;
	font-size: 20px;
}

.link:hover {
	cursor: pointer;
	text-decoration: underline;
}

.link-about-short {
	color: #948fd6;
	color: var(--blue);
}

.link-about-short:hover {
	color: #f89406;
	color: var(--orange);
}

.link-about-long{
	color: #f806e9;
	color: var(--pink);
}

.link-about-long:hover {
	color: #f89406;
	color: var(--orange);
}

.link-portfolio {
	color: #3ed5e0;
	color: var(--light-blue);
}

.link-portfolio:hover {
	color: #f89406;
	color: var(--orange);
}

.btn-github {
	border-color: #3ed5e0;
	border-color: var(--light-blue);
	color: #3ed5e0;
	color: var(--light-blue);
}

.btn-github:hover {
	background-color: #3ed5e0;
	background-color: var(--light-blue);
}

.btn-cv {
	border-color: #f806e9;
	border-color: var(--pink);
	color: #f806e9;
	color: var(--pink);
}

.btn-cv:hover {
	background-color: #f806e9;
	background-color: var(--pink);
}

.btn-codepen {
	border-color: #c31ff4;
	border-color: var(--purple);
	color: #c31ff4;
	color: var(--purple);
}

.btn-codepen:hover {
	background-color: #c31ff4;
	background-color: var(--purple);
}

.btn-linkedin {
	border-color: #68dd70;
	border-color: var(--green);
	color: #68dd70;
	color: var(--green);
}

.btn-linkedin:hover {
	background-color: #68dd70;
	background-color: var(--green);
}

.btn-mail {
	border-color: #f89406;
	border-color: var(--orange);
	color: #f89406;
	color: var(--orange);
}

.btn-mail:hover {
	background-color: #f89406;
	background-color: var(--orange);
}

.text {
	padding: 1rem;
	font-size: x-large;
	line-height: 0.4in;
}


img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

 
.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.cards__item {
  display: flex;
  padding: 1rem;
  width: 20%;
}

@media (max-width: 1400px) {
  .cards__item {
    width: 40%;
  }
}

@media (max-width: 750px) {
  .cards__item {
    width: 100%;
  }
}

.card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px 2px rgba(0,0,0,0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
}

.card__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  -webkit-filter: contrast(70%);
          filter: contrast(70%);
  overflow: hidden;
  position: relative;
  transition: -webkit-filter 0.5s cubic-bezier(.43,.41,.22,.91);
  transition: filter 0.5s cubic-bezier(.43,.41,.22,.91);
  transition: filter 0.5s cubic-bezier(.43,.41,.22,.91), -webkit-filter 0.5s cubic-bezier(.43,.41,.22,.91);

  }


.card__title {
  color: #696969;
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.card__text {
  flex: 1 1 auto;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
}

.card__stack {
  flex: 1 1 auto;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
}

.card__links{
    text-align: center;
}

.card__links a {
    margin: 15px;
    color: var(--orange);
}

.link:hover {
	color: var(--blue);
}
.dev-img {
	width: 100%;
}

.title,
.subtitle {
	font-family: 'VT323', monospace;
	margin: 0;
}

.title {
	font-size: 4rem;
}

.subtitle {
	color: rgb(145, 137, 137);
	font-size: 2.2rem;
}

@media screen and (max-width: 600px) {
	.title,
	.subtitle {
		text-align: center;
	}
}


.menu {
    position: fixed;
    right: 5%;
    font-size: 1.2rem;
}

.menu ul {
    list-style: none;
}
* {
	margin: 0;
	padding: 0;
}
.row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

body {
	font-family: 'Athiti', sans-serif;
	margin: 0 10% 0 10%;
}

.column {
	flex: 25% 1;
}

@media screen and (max-width: 600px) {
	body {
		font-family: 'Athiti', sans-serif;
		margin: 0 10% 0 10%;
	}
	.row {
		flex-direction: column;
	}
	
}

