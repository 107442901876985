
img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

 
.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.cards__item {
  display: flex;
  padding: 1rem;
  width: 20%;
}

@media (max-width: 1400px) {
  .cards__item {
    width: 40%;
  }
}

@media (max-width: 750px) {
  .cards__item {
    width: 100%;
  }
}

.card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px 2px rgba(0,0,0,0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
}

.card__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  filter: contrast(70%);
  overflow: hidden;
  position: relative;
  transition: filter 0.5s cubic-bezier(.43,.41,.22,.91);

  }


.card__title {
  color: #696969;
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.card__text {
  flex: 1 1 auto;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
}

.card__stack {
  flex: 1 1 auto;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
}

.card__links{
    text-align: center;
}

.card__links a {
    margin: 15px;
    color: var(--orange);
}

.link:hover {
	color: var(--blue);
}