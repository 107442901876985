.dev-img {
	width: 100%;
}

.title,
.subtitle {
	font-family: 'VT323', monospace;
	margin: 0;
}

.title {
	font-size: 4rem;
}

.subtitle {
	color: rgb(145, 137, 137);
	font-size: 2.2rem;
}

@media screen and (max-width: 600px) {
	.title,
	.subtitle {
		text-align: center;
	}
}
